import {CustomField} from "./index";
import moment from "moment";
import {apiCategoriesGet} from "../actions/categoriesActions";
import {apiVideoTagsSearch} from "../actions/videoActions";
import {apiStringersSearch} from "../actions/stringerActions";

export const videoFields : {
  [name: string] : CustomField
} = {
  title: {
    id: 'videoTitle',
    label: 'Title',
    type: 'input',
  },
  location: {
    id: 'videoLocation',
    label: 'Location',
    type: 'location'
  },
  filmingDate: {
    id: 'videoFilmingDate',
    label: 'Filming date',
    type: 'datetime',
    options: {
      maxDate: moment().toDate()
    }
  },
  categories: {
    id: 'videoCategories',
    label: 'Categories',
    type: 'multiSelect',
    options: {
      onLoadElements: apiCategoriesGet,
      placeholder: 'Select category'
    }
  },
  tags: {
    id: 'videoTags',
    label: 'Tags',
    type: 'multiSearch',
    options: {
      onSearch: apiVideoTagsSearch
    }
  },
  description: {
    id: 'videoDescription',
    label: 'Description',
    type: 'textarea',
    options: {
      minRows: 4
    }
  },
  author: {
    id: 'videoAuthor',
    label: 'Who uploaded',
    type: 'singleSearch',
    options: {
      placeholder: 'Search stringer',
      onSearch: apiStringersSearch,
      selectCallback: (element: any) => {
        return `${element.user.email }`
      },
      secured: true,
      tips: [
        {
          label: 'andrew.steele@scopal.co.uk',
          value: {
            user: {
              first_name: 'Andrew',
              last_name: 'Steele',
              id: 30430,
              email: 'andrew.steele@scopal.co.uk'
            }
          }
        },
        {
          label: 'cctv@stringershub.com',
          value: {
            user: {
              first_name: 'CCTV',
              last_name: '',
              id: 31009,
              email: 'cctv@stringershub.com'
            }
          }
        }
      ]
    }
  },
  authorEmail: {
    id: 'videoAuthorEmail',
    label: 'Author Email',
    type: 'input'
  },
  source: {
    id: 'videoSource',
    label: 'Source',
    type: 'input',
    options: {
      tips: ['IDF', 'DVIDS USA', 'Quds News Network', 'PRCS', 'Palestine Action', '@Mehrnews', '@PSCupdates'].map(tip => {
        return {
          label: tip,
          value: tip
        }
      })
    }
  },
  referrer: {
    id: 'videoReferrer',
    label: 'Referrer',
    type: 'input',
    options: {
      tips: ['Da', 'P', 'L', 'K', 'M', 'D', 'Yu', 'Y', 'Sc'].map(tip => {
        return {
          label: tip,
          value: tip
        }
      })
    }
  }
}
